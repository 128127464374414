<template>
  <li
    class="flex flex-row hover:bg-gray-100 dark:border-gray-700 dark:hover:bg-gray-700"
  >
    <div class="select-none cursor-pointer flex flex-1 items-center p-4">
      <div class="flex flex-col w-16 h-16 justify-center items-center">
        <img
          v-if="item.image != null"
          :src="item.image.thumb"
          class="mx-auto object-cover rounded h-16 w-16"
        />
      </div>
      <div class="flex-1 px-2 text-left">
        <div class="font-medium dark:text-white">{{ item.name }}</div>
        <p
          class="text-gray-600 dark:text-gray-200 text-sm text-ellipsis overflow-hidden content"
          style="word-break: break-word"
        >
          {{ item.content }}
        </p>
      </div>
      <div
        class="flex flex-col text-gray-600 dark:text-gray-200 text-base font-bold"
      >
        <span
          v-if="item.campaign_price != null"
          class="line-through text-sm font-normal"
        >
          {{ item.price }}{{ currency }}
        </span>
        <span>
          {{ item.campaign_price != null ? item.campaign_price : item.price
          }}{{ currency }}
        </span>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: "ListItem",

  props: ["item", "currency"],

  data: () => ({
    // Data
  }),
};
</script>

<style scoped>
.content {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
