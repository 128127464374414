<template>
  <div class="container mx-auto flex flex-col h-screen dark:bg-gray-900">

    <Marquee v-if="venue.venue.notice" class="bg-red-500 py-2">
      <p class="font-bold text-md text-gray-900 dark:text-white">
        {{ venue.venue.notice }}
      </p>
    </Marquee>
    <div class="flex flex-row p-3 pb-0 items-center">
      <div
        v-if="venue.venue.logo != null"
        class="flex justify-center items-center w-24 h-24 border bg-white dark:border-gray-700 rounded"
      >
        <img class="max-w-full max-h-full" :src="venue.venue.logo" />
      </div>
      <div class="flex flex-col items-start pl-2">
        <h1 class="font-bold text-md text-gray-900 dark:text-white">
          {{ venue.venue.name }}
        </h1>
        <span class="text-sm text-gray-500 dark:text-gray-200">
          {{ venue.venue.district }}, {{ venue.venue.city }}
        </span>
      </div>
    </div>
    <div class="flex flex-nowrap overflow-x-auto no-scrollbar">
      <div
        v-for="(category, cIndex) in venue.menu.content"
        :key="`tab-category-${cIndex}`"
        class="whitespace-nowrap py-4 px-6 border-b-4 border-gray-300 text-gray-600 dark:border-gray-700 dark:text-white font-medium text-sm cursor-pointer"
        :class="{
          'border-indigo-600 text-indigo-600 dark:border-indigo-400 dark:text-indigo-400':
            activeCategory === cIndex && category.type === 'category',
          'border-green-600 text-green-600 dark:border-green-500 dark:text-green-500':
            activeCategory === cIndex && category.type === 'campaigns',
          'border-yellow-500 text-yellow-500 dark:border-yellow-400 dark:text-yellow-400':
            activeCategory === cIndex && category.type === 'bestsellers',
        }"
        @click="goto(cIndex, true)"
      >
        {{ category.name }}
      </div>
    </div>
    <div
      id="list"
      @scroll="handleScroll"
      class="flex flex-1 overflow-y-auto flex-col w-full items-center bg-white dark:bg-gray-900 dark:text-white relative"
    >
      <ListGroup
        v-for="(category, cIndex) in venue.menu.content"
        :key="`category-${cIndex}`"
        :index="cIndex"
        :title="category.name"
        :campaigns="category.type === 'campaigns'"
        :bestsellers="category.type === 'bestsellers'"
      >
        <ListItem
          v-for="(item, iIndex) in category.items"
          :key="`item-${cIndex}-${iIndex}`"
          :item="item"
          :currency="venue.menu.currency.symbol"
          @click.native="selectItem(item)"
        />
      </ListGroup>
    </div>
    <div class="py-2 px-4 border-t dark:border-gray-700 dark:text-white">
      <span class="font-normal text-sm">Bu işletme</span>
      <a
        class="px-px font-bold text-sm"
        href="https://sademenu.com"
        target="_blank"
      >
        Sade Menü
      </a>
      <span class="font-normal text-sm">kullanmaktadır.</span>
    </div>
    <ItemModal v-model="selectedItem" :currency="venue.menu.currency.symbol" />
  </div>
</template>

<script>
import ListGroup from "./components/ListGroup";
import ListItem from "./components/ListItem";
import ItemModal from "./components/ItemModal";

export default {
  name: "Theme1",

  props: ["venue"],

  components: {
    ListGroup,
    ListItem,
    ItemModal,
  },

  data: () => ({
    activeCategory: 0,
    selectedItem: null,
    scrolling: false,
    scrollingTimer: null,
  }),

  methods: {
    selectItem(item) {
      this.selectedItem = item;
    },
    handleScroll(event) {
      if (this.scrolling) {
        return;
      }
      for (let i = event.target.children.length - 1; i >= 0; i--) {
        if (event.target.children[i].offsetTop < event.target.scrollTop) {
          const index = parseInt(event.target.children[i].dataset.category);
          if (index != this.activeCategory) {
            this.goto(index);
          }
          return false;
        }
      }
    },
    goto(cIndex, scroll = false) {
      if (scroll) {
        clearTimeout(this.scrollingTimer);
        this.scrolling = true;
        this.scrollingTimer = setTimeout(() => {
          this.scrolling = false;
        }, 1000);
      }
      this.activeCategory = cIndex;
      const container = document.querySelector(".no-scrollbar");
      const middle = container.children[cIndex];
      const scrollLeft =
        middle.offsetLeft + middle.offsetWidth / 2 - container.offsetWidth / 2;

      container.scrollTo({
        left: scrollLeft,
        behavior: "smooth",
      });

      if (scroll) {
        const listContainer = document.querySelector("#list");
        const middle = listContainer.children[cIndex];
        listContainer.scrollTo({
          top: middle.offsetTop,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style>
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
