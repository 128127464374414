<template>
  <div class="menu dark:bg-gray-900">
    <div v-if="loading" class="flex h-screen justify-center items-center">
      <div class="relative flex w-1/2 justify-center items-center">
        <lottie :options="lottieOptions" />
        <svg
          class="absolute w-1/4 text-black dark:text-white fill-current"
          viewBox="0 0 250 250"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M49 75.7109V17H118.328V34.2969H68.4062V59.2578H118.328V117.969H49V100.672H100.117V75.7109H49Z"
          ></path>
          <path
            d="M183.508 75.7109H151.797V117.969H132.391V17H201.719V117.969H183.508V75.7109ZM151.797 34.2969V59.2578H183.508V34.2969H151.797Z"
          ></path>
          <path
            d="M49 232.969V132H111.016L118.328 140.859V224.109L111.086 232.969H49ZM100.117 149.297H68.4062V215.672H100.117V149.297Z"
          ></path>
          <path
            d="M151.797 149.297V174.258H201.719V190.711H151.797V215.672H201.719V232.969H132.391V132H201.719V149.297H151.797Z"
          ></path>
        </svg>
      </div>
    </div>
    <div
      v-if="error"
      class="flex flex-col h-screen justify-center items-center"
    >
      <p class="text-lg font-medium mb-6">Menü Yüklenemedi</p>
      <button
        type="button"
        class="py-2 px-4 bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
        @click="refresh"
      >
        Tekrar Dene
      </button>
    </div>
    <div v-if="!loading && !error">
      <a
        href="https://panel.sademenu.com/order/new"
        v-if="venue.venue.expire_status === 1"
        class="fixed w-full h-12 flex justify-center items-center bg-yellow-300 text-yellow-800 dark:bg-yellow-600 dark:text-yellow-100 text-sm"
      >
        Menü lisansı sona erdi. Lütfen lisansınızı yenileyin.
      </a>
      <a
        v-if="venue.venue.code === 'sadecafe'"
        href="https://panel.sademenu.com/register"
        class="fixed w-full h-12 flex justify-center items-center bg-indigo-600 text-white dark:bg-indigo-700 text-sm"
      >
        Kendi menünüzü oluşturmak için&nbsp;<u>tıklayın</u>.
      </a>
      <Theme1
        :venue="venue"
        :class="{
          'pt-12':
            venue.venue.expire_status === 1 || venue.venue.code === 'sadecafe',
        }"
      />
      <expired-modal v-if="venue.venue.expire_status === 2" />
    </div>
  </div>
</template>

<script>
import lottie from "vue-lottie/src/lottie.vue";
import * as animationData from "@/assets/loader.json";
import Theme1 from "@/themes/Theme1";
import ExpiredModal from "@/components/ExpiredModal.vue";

export default {
  name: "Menu",
  title: "Sade Menü",

  components: {
    lottie,
    Theme1,
    ExpiredModal,
  },

  data: () => ({
    uuid: null,
    venue: null,
    loading: true,
    error: false,
    lottieOptions: { animationData: animationData.default },
  }),

  beforeMount() {
    let uuid = localStorage.getItem("uuid");
    if (uuid == null) {
      uuid = this.$uuid.v4();
      localStorage.setItem("uuid", uuid);
    }
    this.uuid = uuid;
  },

  mounted() {
    this.get();
  },

  methods: {
    get() {
      this.$axios
        .get(`/venue/${this.$route.params.code}`, {
          headers: {
            "X-UUID": this.uuid,
          },
        })
        .then((response) => {
          document.title = `${response.data.venue.name} - Sade Menü`;
          this.venue = response.data;
          this.loading = false;
        })
        .catch((e) => {
          if (e.response.status === 404) {
            window.location.href = "https://sademenu.com";
          } else {
            this.loading = false;
            this.error = true;
          }
        });
    },
    refresh() {
      if (!this.loading) {
        this.error = false;
        this.loading = true;
        this.get();
      }
    },
  },
};
</script>
