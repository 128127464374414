<template>
  <transition name="fade">
    <div
      class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-10 p-4"
    >
      <div
        class="modal-overlay absolute w-full h-full bg-gray-900 dark:bg-gray-800 opacity-70"
      ></div>
      <div
        class="shadow-lg rounded-2xl bg-white dark:bg-gray-900 w-max z-40 modal-content overflow-y-auto"
      >
        <div class="w-full">
          <div
            class="flex h-full flex-col justify-between px-4 py-12 dark:text-white"
          >
            <h1 class="text-2xl font-bold mb-2">Menü Lisansı Sona Erdi</h1>
            <p class="mb-2 break-words">
              Menünüzü kullanmaya devam edebilmek için lütfen panel üzerinden
              lisansınızı yenileyin.
            </p>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ExpiredModal",
};
</script>
