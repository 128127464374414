<template>
  <div class="w-full" :data-category="index">
    <div
      class="sticky -top-px p-3 text-left font-bold text-base bg-gray-200 dark:bg-gray-800 z-10"
      :class="{
        'bg-green-600 text-white dark:bg-green-600': campaigns === true,
        'bg-yellow-500 text-white dark:bg-yellow-500': bestsellers === true,
      }"
    >
      {{ title }}
    </div>
    <ul class="flex flex-col divide divide-y w-full">
      <slot />
    </ul>
  </div>
</template>

<script>
export default {
  name: "ListGroup",

  props: ["index", "title", "campaigns", "bestsellers"],

  data: () => ({
    // Data
  }),
};
</script>
