<template>
  <transition name="fade">
    <div
      v-show="item != null"
      class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-10"
    >
      <div
        class="modal-overlay absolute w-full h-full bg-gray-900 dark:bg-gray-800 opacity-50"
        @click="closeModal"
      ></div>
      <button
        class="absolute top-9 right-6 w-9 h-9 text-white bg-gray-600 bg-opacity-50 rounded-full z-50"
        @click="closeModal"
      >
        X
      </button>
      <div
        class="shadow-lg rounded-2xl bg-white dark:bg-gray-900 w-max z-40 modal-content overflow-y-auto"
      >
        <div class="w-full">
          <div v-if="item != null" class="flex h-full flex-col justify-between">
            <img v-if="item.image != null" :src="item.image.medium" />
            <div v-else class="h-10"></div>
            <div class="p-4 dark:text-white">
              <h1 class="text-2xl font-bold mb-2">{{ item.name }}</h1>
              <p v-if="item.content != null" class="mb-2 break-words">
                {{ item.content }}
              </p>
              <div
                v-if="item.price != null"
                class="flex items-center justify-center mb-2"
              >
                <span
                  v-if="item.campaign_price != null"
                  class="line-through text-xl font-normal mx-1"
                >
                  {{ item.price }}{{ currency }}
                </span>
                <span class="text-2xl font-bold mx-1">
                  {{
                    item.campaign_price != null
                      ? item.campaign_price
                      : item.price
                  }}{{ currency }}
                </span>
              </div>
              <div v-if="item.promotions.length > 0" class="mt-2">
                <h2 class="text-left text-base font-bold">Promosyonlar</h2>
                <ul class="flex flex-col divide divide-y w-full">
                  <li
                    v-for="(promotion, pIndex) in item.promotions"
                    :key="`promotion-${pIndex}`"
                    class="flex flex-row dark:border-gray-700"
                  >
                    <div
                      class="select-none cursor-pointer flex flex-1 items-center p-4"
                    >
                      <div
                        class="flex flex-col w-14 h-14 justify-center items-center"
                      >
                        <img
                          v-if="promotion.image != null"
                          :src="promotion.image.thumb"
                          class="mx-auto object-cover rounded h-14 w-14 rounded-full"
                        />
                      </div>
                      <div class="flex-1 mp-2 justify-start">
                        <div class="font-medium dark:text-white">
                          {{ promotion.name }}
                        </div>
                      </div>
                      <div
                        class="flex flex-col text-gray-600 dark:text-gray-200 text-base font-bold"
                      >
                        <span
                          v-if="
                            promotion.promotional_price != null ||
                            promotion.campaign_price != null
                          "
                          class="line-through text-sm font-normal"
                        >
                          {{ promotion.price }}{{ currency }}
                        </span>
                        <span>
                          {{
                            promotion.promotional_price != null
                              ? promotion.promotional_price
                              : promotion.campaign_price != null
                              ? promotion.campaign_price
                              : promotion.price
                          }}{{ currency }}
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ItemModal",

  props: {
    value: {
      type: Object,
      required: false,
      default: null,
    },
    currency: {
      type: String,
      required: false,
      default: null,
    },
  },

  methods: {
    closeModal() {
      this.$emit("input", null);
    },
  },

  computed: {
    item: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style scoped>
.modal-content {
  max-height: 95%;
  max-width: 95%;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
