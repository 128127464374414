var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container mx-auto flex flex-col h-screen dark:bg-gray-900"},[(_vm.venue.venue.notice)?_c('Marquee',{staticClass:"bg-red-500 py-2"},[_c('p',{staticClass:"font-bold text-md text-gray-900 dark:text-white"},[_vm._v(" "+_vm._s(_vm.venue.venue.notice)+" ")])]):_vm._e(),_c('div',{staticClass:"flex flex-row p-3 pb-0 items-center"},[(_vm.venue.venue.logo != null)?_c('div',{staticClass:"flex justify-center items-center w-24 h-24 border bg-white dark:border-gray-700 rounded"},[_c('img',{staticClass:"max-w-full max-h-full",attrs:{"src":_vm.venue.venue.logo}})]):_vm._e(),_c('div',{staticClass:"flex flex-col items-start pl-2"},[_c('h1',{staticClass:"font-bold text-md text-gray-900 dark:text-white"},[_vm._v(" "+_vm._s(_vm.venue.venue.name)+" ")]),_c('span',{staticClass:"text-sm text-gray-500 dark:text-gray-200"},[_vm._v(" "+_vm._s(_vm.venue.venue.district)+", "+_vm._s(_vm.venue.venue.city)+" ")])])]),_c('div',{staticClass:"flex flex-nowrap overflow-x-auto no-scrollbar"},_vm._l((_vm.venue.menu.content),function(category,cIndex){return _c('div',{key:("tab-category-" + cIndex),staticClass:"whitespace-nowrap py-4 px-6 border-b-4 border-gray-300 text-gray-600 dark:border-gray-700 dark:text-white font-medium text-sm cursor-pointer",class:{
        'border-indigo-600 text-indigo-600 dark:border-indigo-400 dark:text-indigo-400':
          _vm.activeCategory === cIndex && category.type === 'category',
        'border-green-600 text-green-600 dark:border-green-500 dark:text-green-500':
          _vm.activeCategory === cIndex && category.type === 'campaigns',
        'border-yellow-500 text-yellow-500 dark:border-yellow-400 dark:text-yellow-400':
          _vm.activeCategory === cIndex && category.type === 'bestsellers',
      },on:{"click":function($event){return _vm.goto(cIndex, true)}}},[_vm._v(" "+_vm._s(category.name)+" ")])}),0),_c('div',{staticClass:"flex flex-1 overflow-y-auto flex-col w-full items-center bg-white dark:bg-gray-900 dark:text-white relative",attrs:{"id":"list"},on:{"scroll":_vm.handleScroll}},_vm._l((_vm.venue.menu.content),function(category,cIndex){return _c('ListGroup',{key:("category-" + cIndex),attrs:{"index":cIndex,"title":category.name,"campaigns":category.type === 'campaigns',"bestsellers":category.type === 'bestsellers'}},_vm._l((category.items),function(item,iIndex){return _c('ListItem',{key:("item-" + cIndex + "-" + iIndex),attrs:{"item":item,"currency":_vm.venue.menu.currency.symbol},nativeOn:{"click":function($event){return _vm.selectItem(item)}}})}),1)}),1),_vm._m(0),_c('ItemModal',{attrs:{"currency":_vm.venue.menu.currency.symbol},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-2 px-4 border-t dark:border-gray-700 dark:text-white"},[_c('span',{staticClass:"font-normal text-sm"},[_vm._v("Bu işletme")]),_c('a',{staticClass:"px-px font-bold text-sm",attrs:{"href":"https://sademenu.com","target":"_blank"}},[_vm._v(" Sade Menü ")]),_c('span',{staticClass:"font-normal text-sm"},[_vm._v("kullanmaktadır.")])])}]

export { render, staticRenderFns }